import { merge } from "theme-ui"
import tailwind from "@theme-ui/preset-tailwind"

const theme = merge(tailwind, {
  config: {
    initialColorModeName: `dark`,
    useColorSchemeMediaQuery: `system`,
  },
  colors: {
    // primary: tailwind.colors.green[5],
    primary: `#52FF00`,
    darkGreen: `0D130C`,
    btngreen: `#567523`,
    darkbtngreen: `#445E1B`,
    secondary: tailwind.colors.sky[7],
    text: `rgba(255, 255, 255, 0.9)`,
    secondaryText: `#878593`,
    heading: tailwind.colors.white,
    background: "#111811",
    divider: tailwind.colors.gray[8],
    textMuted: tailwind.colors.gray[5],
    icon_brightest: tailwind.colors.white,
    icon_darker: tailwind.colors.gray[7],
    icon_darkest: tailwind.colors.gray[8],
    icon_red: tailwind.colors.red[6],
    icon_blue: tailwind.colors.blue[6],
    icon_orange: tailwind.colors.orange[5],
    icon_yellow: tailwind.colors.yellow[5],
    icon_pink: tailwind.colors.pink[5],
    icon_purple: tailwind.colors.purple[6],
    icon_green: tailwind.colors.green[5],
  },
  breakpoints: [`400px`, `600px`, `900px`, `1200px`, `1600px`],
  footer: {
    textAlign: `center`,
    display: `block`,
    position: `absolute`,
    bottom: 0,
    color: `textMuted`,
    px: [2, 3],
    py: [3, 4],
  },
  styles: {
    root: {
      margin: 0,
      padding: 0,
      boxSizing: `border-box`,
      textRendering: `optimizeLegibility`,
      WebkitFontSmoothing: `antialiased`,
      MozOsxFontSmoothing: `grayscale`,
      color: `text`,
      fontFamily: `Inter, sans-serif`,
      a: {
        textDecoration: `none`,
        transition: `all 0.3s ease-in-out`,
        "&:hover": {
          color: `primary`,
        },
      },
    },

    p: {
      fontSize: [`2xl`, `3xl`],
      letterSpacing: `-0.003em`,
      lineHeight: `body`,
      "--baseline-multiplier": 0.179,
      "--x-height-multiplier": 0.35,
      color: `text`,
    },
    blockquote: {
      marginLeft: 0,
      span: {
        display: `inline-block`,
        fontSize: `6xl`,
        fontWeight: `medium`,
        color: `${tailwind.colors.teal[6]}`,
        border: `2.5px solid ${tailwind.colors.teal[6]}`,
        backgroundColor: `${tailwind.colors.grayDark}`,
        borderRadius: `full`,
        width: `6.2rem`,
        height: `6.2rem`,
      },
    },
    h1: {
      fontSize: [`6xl`, `7xl`, `8xl`],
      letterSpacing: ["wide", "normal", "tighter"],
      fontWeight: ["medium", "medium", "normal"],
      lineHeight: [1.25, 1.2, 1.2],
      mb: 3,
      // textShadow: `rgba(255, 255, 255, 0.15) 0px 5px 35px`,
      color: `heading`,
    },
    h2: {
      fontSize: [`5xl`, `6xl`, `7xl`],
      letterSpacing: "normal",
      fontWeight: "medium",
      lineHeight: "1.2",
      mb: 3,
      // textShadow: `rgba(255, 255, 255, 0.15) 0px 5px 35px`,
      color: `heading`,
    },
    h3: {
      fontSize: [`3xl`, `4xl`, `5xl`],
      mt: 3,
      color: `heading`,
    },
    h4: {
      fontSize: [`2xl`, `3xl`, `4xl`],
      fontWeight: `normal`,
      opacity: 0.6,
      color: `heading`,
    },
    h5: {
      fontSize: [`xl`, `2xl`, `3xl`],
      fontWeight: `normal`,
      color: `heading`,
    },
    h6: {
      fontSize: `xl`,
      mb: 2,
      color: `heading`,
    },
    svg: {
      width: `100%`,
      border: `1px solid red`,
    },
  },
  layout: {
    container: {
      maxWidth: `5xl`,
    },
  },
  buttons: {
    cta: {
      color: `rgba(255, 255, 255, 0.8)`,
      border: `none`,
      fontWeight: `medium`,
      backgroundColor: `btngreen`,
      cursor: `pointer`,
      alignSelf: `center`,
      px: 4,
      py: 1,
      fontSize: [`xl`, `2xl`, `3xl`],
      "&:hover": {
        backgroundColor: `darkbtngreen`,
      },
    },
  },
  texts: {
    bigger: {
      h1: {
        fontSize: [`2.4rem`, `3.4rem`, `3.8rem`, `4.4rem`, `4.8rem`, `6.4rem`],
        lineHeight: `0.8`,
        letterSpacing: `0rem`,
        fontWeight: [`normal`, `semibold`, `semibold`, `bold`],
      },
      p: {
        fontSize: [`2xl`, `2xl`, `3xl`, `3xl`, `4xl`],
        opacity: 0.7,
      },
    },
    big: {
      h2: {
        fontSize: [`3.2rem`, `3.6rem`, `4rem`, `5rem`, `5.4rem`],
        fontWeight: [`medium`, `medium`, `medium`, `semibold`],
      },
      p: {
        fontSize: [`xl`, `2xl`, `2xl`, `2xl`, `3xl`],
      },
    },
    medium: {
      h2: {
        fontSize: [`3xl`, `4xl`, `5xl`],
        fontWeight: `medium`,
      },
      p: {
        fontSize: [`xl`, `2xl`],
      },
    },
    small: {
      h2: {
        fontSize: [`2xl`, `3xl`, `4xl`],
        fontWeight: `medium`,
      },
      p: {
        fontSize: [`lg`, `xl`],
      },
    },
  },
  links: {
    nav: {
      display: `inline-block`,
      position: `relative`,
      width: `auto`,
      color: `text`,
      textDecoration: `none`,
      cursor: `pointer`,
      fontSize: [`xl`, `2xl`, `3xl`],
      transition: `all 0.3s ease-in-out`,
      "&:hover": {
        textDecoration: `none`,
        color: `primary`,
        transition: `all 0.3s ease-in-out`,
        transform: `translateY(-2px)`,
      },
      ":before": {
        content: '""',
        position: "absolute",
        width: "100%",
        height: "3px",
        bottom: 0,
        left: 0,
        backgroundColor: "primary",
        visibility: "hidden",
        transform: "scaleX(0)",
        transformOrigin: "bottom left",
        transition: "all 0.3s ease-in-out 0s",
      },
      ":hover:before": {
        visibility: "visible",
        transform: "scaleX(0.7)",
      },
    },
    footer: {
      a: {
        color: `text`,
        textDecoration: `none`,
        cursor: `pointer`,
        fontSize: [`1.2rem`, `1.3rem`, `1.4rem`, `1.5rem`, `1.6rem`],
        transition: `all 0.3s ease-in-out`,
        "&:hover": {
          opacity: 0.8,
          textDecoration: `none`,
          color: `primary`,
        },
      },
      p: {
        fontSize: [`1.3rem`, `1.4rem`, `1.5rem`, `1.6rem`, `1.7rem`],
        opacity: 0.5,
      },
    },
  },
  forms: {
    feedback: {
      fontSize: [`xl`, `xl`, `2xl`],
      label: {
        color: `text`,
        mt: `1rem`,
      },
      input: {
        width: `60%`,
        color: `rgba(255,255,255, 0.5)`,
      },
      textarea: {
        color: `rgba(255,255,255, 0.5)`,
        backgroundColor: `rgba(255,255,255, 0.05)`,
      },
    },
  },
  about: {
    // fontFamily: `Muli, sans-serif`,
    textAlign: `center`,
    h2: {
      fontSize: [`4xl`, `5xl`, `6xl`],
      fontWeight: `normal`,
      lineHeight: 1,
      letterSpacing: `0.2rem`,
    },
    h3: {
      fontSize: [`xl`, `2xl`, `2xl`, `3xl`, `3xl`],
      fontWeight: `normal`,
      letterSpacing: `0.1rem`,
      marginBlock: [`1.6rem`, `2rem`, `2.4rem`, `2.8rem`],
    },
    h4: {
      fontSize: [`lg`, `xl`, `2xl`, `2xl`, `3xl`],
      fontWeight: `normal`,
      marginBlock: [`1rem`, `1.4rem`, `1.6rem`, `1.8rem`],
    },
    p: {
      display: `block`,
      fontSize: [`md`, `lg`, `xl`, `2xl`],
      color: `secondaryText`,
      lineHeight: 1.8,
    },
    a: {
      display: `block`,
      fontSize: [`lg`, `xl`, `2xl`],
      color: `secondaryText`,
      lineHeight: 1.8,
    },
  },

  features: {
    // fontFamily: `Muli, sans-serif`,
    textAlign: `center`,
    h2: {
      fontSize: [`4xl`, `5xl`, `6xl`],
      fontWeight: `normal`,
      margin: 0,
    },
    h4: {
      color: `secondaryText`,
    },
    p: {
      display: `block`,
      fontSize: [`xl`, `2xl`, `3xl`],
      color: `secondaryText`,
      lineHeight: 1.8,
    },
  },
})

export default theme

/*

linear-gradient(200.41deg, rgba(99, 29, 62, 0.4) -5.53%, rgba(35, 98, 111, 0.5) 97.77%);


--blue-track: linear-gradient(0deg, rgb(255 255 255/0) 0%, hsla(220,62%,25%,0.5) 15%, hsla(220,62%,25%,0.5) 85%, rgb(255 255 255/0) 100%);

    --green-track: linear-gradient(0deg, rgb(255 255 255/0) 0%, hsla(150,60%,13%,0.5) 15%, hsla(150,60%,13%,0.5) 85%, rgb(255 255 255/0) 100%);
*/
