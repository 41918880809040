exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-comingsoon-tsx": () => import("./../../../src/pages/comingsoon.tsx" /* webpackChunkName: "component---src-pages-comingsoon-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-templates-gradeup-tsx": () => import("./../../../src/templates/gradeup.tsx" /* webpackChunkName: "component---src-templates-gradeup-tsx" */)
}

